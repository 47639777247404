$aqua-blue: #00b3e3;
$cool-grey: #99999a;
$dark-blue: #003b71;

@font-face {
    font-family: AvenirNextLTPro;
    font-weight: bold;
    src: url("fonts/AvenirNextLTPro-Demi.otf") format("opentype");
}

@font-face {
    font-family: AvenirNextLTPro;
    font-weight: regular;
    src: url("fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

.German [lang='en'] { display: none; }
.English [lang='de'] { display: none; }

html, body, div#root {
    border: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

body {
    font-family: AvenirNextLTPro;
    font-size: 14px;
    line-height: 20px;
    color: hsl(0,0%,60%);
}

div.App {
    position: relative;
    display: grid;
    grid-template-areas:
        "logos"
        "toolbar"
        "content"
        "footer";
    grid-template-rows: min-content min-content auto min-content;
    height: 100%;
    min-height: 100%;
    > * {
        padding: 1em;
    }
}
div.logos {
    grid-area: logos;
    min-height: 3em;
    padding: 1em 1.5em;
    background-size: contain;
    background-position: center left;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-image: url('amilogo.svg')
}

div.welcomebar {
    grid-area: toolbar;
    border-top: 1px solid $aqua-blue;
    border-bottom: 1px solid darken($aqua-blue,10%);
    background: $aqua-blue;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    h1 {
        margin: 0;
        border: 0;
        color: white;
        font-variant: small-caps;
        text-transform: lowercase;
        font-weight: normal;
        font-size: 24px;
    }
    button {
        border: 1px solid darken($aqua-blue,10%);
        color: white !important;
        border-radius: 16px;
        padding: 6px 10px;
        color: $dark-blue;
        background: $aqua-blue;
        font-variant: normal;
        white-space: nowrap;
        //display: none !important;
        &.languagetoggle { display: none; }
    }
}

.logged_in h1 { display: none; }

div.content {
    grid-area: content;
    color: $dark-blue;
    > div { margin: 0.5em 1em; }
    div.instructionsheader {
        font-weight: bold;
        color: $aqua-blue;
    }
    a {
        color: $dark-blue;
        text-decoration: underline;
        &:hover { color: $aqua-blue; }
    }
}

.emailinput {
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    outline: 0;
    font-size: 16px;
    max-width: 80vw;
    > * {
        margin: 0.5em 0;
        padding: 8px 16px;
    }
    > input {
        border-radius: 100px;
        border: 1px solid black;
        font-size: 16px;
    }
    > input[type='submit'] {
        cursor: pointer;
        width: min-content;
        background: hsl(0,0%,75%);
    }
    > span {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > input {
            margin-left: 0;
            margin-right: 1em;
        }
    }
}

div.footer {
    grid-area: footer;
    margin-top: 1px solid darken($dark-blue,20%);
    background: $dark-blue;
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    color: white;
    > * {
        padding: 1em;
        > strong {
            display: block;
            color: white;
            font-variant: small-caps;
            text-transform: lowercase;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            li {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                span {
                    display: inline-block;
                    margin: 0 0.5em;
                }
            }
        }
    }
    a {
        text-decoration: none;
        color: white;
        &:hover {
            text-decoration: underline;
        }
    }
    > :last-child {
        width: 100%;
    }
}

.resourceSubscribed {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * { margin: 2em; }
    a {
        max-width: 60%;
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(1.1);
            transition: all 0.2s ease-in-out;
            > img { border: 1px solid blue; }
        }
        > img {
            width: 100%;
            border: 1px solid hsl(0,0%,20%);
        }
    }
}
